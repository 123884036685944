$(document).ready(function(){
    $('.navigation ul li > a').click(function() {
        if ($(this).parent().find('ul').length > 0) {
            var active = $(this).parent().attr('data-active');
            if (active == 1) {
                $(this).parent().toggleClass('opened');
                $(this).parent().find('ul').hide();
                $(this).parent().attr('data-active', '0');
            } else {
                // Закрываем всё
                $(".navigation ul li").find('ul').hide();
                $(".navigation ul li").attr('data-active', '0');
                $(".navigation ul li").removeClass('opened');

                $(this).parent().toggleClass('opened');
                $(this).parent().find('ul').show();
                $(this).parent().attr('data-active', '1');
            }
            return false;
        }
    });
    $('.navigation li > a').mouseup(function()  {
        return false;
    });
    $(document).mouseup(function() {
        // Закрываем всё
        $(".navigation ul li").find('ul').hide();
        $(".navigation ul li").attr('data-active', '0');
        $(".navigation ul li").removeClass('opened');
    });

    $(".gallery-list .item a").colorbox({rel: '.gallery-list .item a', maxWidth: '95%', maxHeight: '95%'});
});



document.addEventListener('DOMContentLoaded', () => {
    var twoDaysFromNow = (new Date().getTime() / 1000) + (86400 * 7);

    var flipdown = new FlipDown(twoDaysFromNow, {
            headings: ["Дней", "Часов", "Минут", "Секунд"],
        })
        .start()
        .ifEnded(() => {
            //console.log('The countdown has ended!');
        });
});
